import {createBrowserRouter,RouterProvider} from 'react-router-dom'
import React from 'react';
import './App.css';
import ComingSoon from './Pages/ComingSoon';
import Testing from './Pages/Testing';
import ReactGA from 'react-ga4';

// Initialize Google Analytics
const TRACKING_ID = "G-4R82GED30F"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID); 

// ReactGA.event({
//   category: "your category",
//   action: "your action",
//   label: "your label", // optional
//   value: 99, // optional, must be a number
//   nonInteraction: true, // optional, true/false
//   transport: "xhr", // optional, beacon/xhr/image
// });

function App() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });

  const router = createBrowserRouter([
    {
      path: "/",
      element:<> <ComingSoon /> </>
    },
    {
      path: "/ronistesting",
      element:<> <Testing /> </>
    }
  ])

  return (
    <>
      <RouterProvider router={router}/>
    </>
  );
}

export default App;
