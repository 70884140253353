import React from 'react'

function ComingSoon() {

  
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#4B279B] to-[#DF99D8] text-white p-8">
    <div className="w-full max-w-md text-center mb-12 lg:mb-8">
      <img 
        src="/default-monochrome-white.svg" 
        alt="Getfam Logo" 
        width={150} 
        height={60} 
        className="mx-auto mb-10"
      />
      <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
      <p className="text-l mb-8">We're working hard to bring you something amazing. Stay tuned!</p>
      <div className="w-full max-w-md relative group">
          <input 
            type="email" 
            placeholder="Enter your email" 
            className="w-full pl-4 pr-32 py-3 bg-white/10 backdrop-blur-md border border-white/20 rounded-full text-white placeholder-white/60 focus:outline-none focus:ring-2 focus:ring-white/50 transition-all duration-300"
          />
          <button 
            className="absolute right-1 top-1 bottom-1 px-6 bg-white text-[#ac71c3] rounded-full hover:bg-purple-100 transition-all duration-300 transform group-hover:scale-105"
          >
            Notify Me
          </button>
        </div>
        <p className="mt-4 text-sm text-white/70">Be the first one to know about it.</p>
      
    </div>
  </div>
  )
}

export default ComingSoon
