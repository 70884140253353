import React, { useState } from 'react'
import { Toaster, toast } from 'sonner'
import '../App.css'
import Confetti from 'react-confetti-boom';
import ReactGA from 'react-ga4';

// Initialize Google Analytics
const TRACKING_ID = "G-4R82GED30F"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Testing() {
  ReactGA.send({ hitType: "pageview", page: "/ronistesting", title: "Ron is testing" });

  const [email, setEmail] = useState('')
  const [showConfetti, setShowConfetti] = useState(false)

  const insertIntoDB = (email, toastid) => {
    fetch('http://127.0.0.1:8000/insert', {
    // fetch('https://getfam.koyeb.app/insert', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email})
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      if(data.message === 'User already exists') {
        ReactGA.event({
          category: "User",
          action: "Already Exists"
        })
        toast.info('You have already been added to the list.', {id: toastid})
      }
      else if (data.message === 'User inserted successfully') {
        toast.success('You have been successfully added to the list!', {id: toastid})
        setShowConfetti(true)
        setTimeout(()=>{setShowConfetti(false)}, 5000)
        setEmail('')

        ReactGA.event({
          category: "User",
          action: `New Sign Up`
        })
      }
      
    })
    .catch(error => {
      toast.error('An error occurred. Please try again later.',{id: toastid})
      console.log('Error:', error)
    })
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }
  
  const handleNotifyClick = () => {
    if(!email) {
      toast.warning('Please enter an email address.')
    } else if (!validateEmail(email)) {
      toast.warning('Please enter a valid email address.')
    } else {
      const toastid = toast.loading('Adding you to the list...')
      
      insertIntoDB(email, toastid)      
    }
  }

  return (
    <>
    
    <div className="min-h-screen md:min-h-screen lg:min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#4B279B] to-[#DF99D8] text-white p-8">
   
      {showConfetti && <Confetti className="h-screen" x={0.5} y={0.6} colors={["#29cdff", "#78ff44", "#ff718d", "#fdff6a"]} launchSpeed={1.2} mode='boom' particleCount={60} shapeSize={16}/>}
    <Toaster richColors position='top-center'/>
    

    <div className="w-full max-w-md text-center mb-12 lg:mb-8">
      <img 
        src="/default-monochrome-white.svg" 
        alt="Getfam Logo" 
        width={150} 
        height={60} 
        className="mx-auto mb-10"
      />
      <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
      <p className="text-l mb-8">Connecting people to share subscription family plans and save money.</p>
      
      <div className="w-full max-w-md relative group">
              
          <input 
            type="email" 
            placeholder="Enter your email" 
            className="w-full pl-4 pr-32 py-3 bg-white/10 backdrop-blur-md border border-white/20 rounded-full text-white placeholder-white/60 focus:outline-none focus:ring-2 focus:ring-white/50 transition-all duration-300"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleNotifyClick()}
          />
          
          <button 
            onClick={handleNotifyClick}
            className="absolute right-1 top-1 bottom-1 px-6 bg-white text-[#ac71c3] rounded-full hover:bg-purple-100 transition-all duration-300 transform group-hover:scale-105"
          >
            Notify Me
          </button>
        </div>
        <p className="mt-4 text-sm text-white/70">Be the first one to know about the launch.</p>
      
    </div>
  </div>
  </>
  )
}

export default Testing
